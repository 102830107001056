
import React, {  useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/item.css'
import { Galleria } from 'primereact/galleria';

const Item = ({ product }) => {
    const [images, setImages] = useState([]);

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        if (product && product.images) {
            const imagesToShow = product.images.map(image => ({
                itemImageSrc: `${image}`,
                thumbnailImageSrc: `${image}`,
                alt: product.title,
                title: product.title
            }));
            setImages(imagesToShow);
        }
    }, [product]);

    const itemTemplate = (item) => {
        return (
            <div className="galleria-item-card">
                <Link to={`/products/${product._id}`} >
                    <img src={item.itemImageSrc} alt={item.alt} className="recommended-product-image" />
                </Link>
            </div>
        );
    };


    return (
        <div className="recommended-product-card">

            <Link to={`/products/${product._id}`} >

                <div className='galleria-container'>
                    <Galleria
                        value={images}
                        numVisible={1}
                        circular
                        showThumbnails={false}
                        showItemNavigators
                        item={itemTemplate}
                        className="custom-galleria"
                    />
                </div>

            </Link>

            <div className="recommended-product-info">

                    <div className="content-price-title">

                        <div className='container-title-card'>
                        <Link to={`/products/${product._id}`} onClick={handleClick}>
                            <h3 className="recommended-product-title">{product.title}</h3>
                            </Link>
                        </div>

                        <div className="content-price-card">
                            <p>
                
                                {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })
                                    .format(product.priceARS)} ARS
                            </p>
                            {product.priceUSD && (
                                <p>
           
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                        .format(product.priceUSD)} USD
                                </p>
                            )}
                        </div>

                    </div>

            </div>
          
        </div>
    );
}

export default Item;