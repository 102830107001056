import React, { createContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { REACT_APP_HOST_HOOKS_DEV } from '../config/config.js';

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/categories`)
            .then(response => response.json())
            .then(data => setCategories(data))
            .catch(error => console.error('Error al obtener categorías:', error));
    }, [children]);

    const addCategory = (category) => {
        fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/categories`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: category }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message === 'Categoría agregada') {
                    toast.success('Categoría agregada exitosamente!');
                    setCategories([...categories, { name: category }]);
                } else {
                    toast.error('Error al agregar categoría');
                }
            })
            .catch(error => toast.warn('El nombre de la categoría no puede estar vacío'));
    };

    return (
        <CategoriesContext.Provider value={{ categories, addCategory, setCategories }}>
            {children}
        </CategoriesContext.Provider>
    );
};