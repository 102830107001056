import React, { useContext, useRef, useState, useEffect } from 'react';
import '../styles/itemDetail.css';
import { CartContext } from '../context/cartContext.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DetailBreadCrumb from './BreadCrumb.jsx';
import { Galleria } from 'primereact/galleria';
import { ProgressSpinner } from 'primereact/progressspinner';
import PaymentInfoLinks from './DrawerMethodsPay/PaymentInfoLinks.jsx';

const ItemDetail = ({ producto }) => {
    const { addToCart, loading, error, success } = useContext(CartContext);
    const galleria = useRef(null);
    const [numVisible, setNumVisible] = useState(5);
    const [thumbnailsPosition, setThumbnailsPosition] = useState('left');

    // Función para determinar numVisible y thumbnailsPosition en función del ancho de la pantalla
    const determineResponsiveSettings = () => {
        if (window.innerWidth >= 1024) {
            setNumVisible(5);
            setThumbnailsPosition('left');
        } else if (window.innerWidth >= 1000) {
            setNumVisible(3);
            setThumbnailsPosition('left');
        } else {
            setNumVisible(1);
            setThumbnailsPosition('bottom');
        }
    };

    useEffect(() => {
        determineResponsiveSettings();

        const handleResize = () => {
            determineResponsiveSettings();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAddToCart = () => {
        const productID = producto._id;
        addToCart(productID);

        if (loading === false) {
            console.log('Agrego producto al carrito.');
        } else {
            toast.error('Error al agregar producto.');
        }
    };

    if (!producto) {
        return <p>Cargando...</p>;
    }

    const itemTemplate = (item) => {
        return (
            <div className="items-main-gallery">
                <img src={`${item.itemImageSrc}`} alt={item.alt} className="galleria-image" />
            </div>
        );
    };

    const thumbnailTemplate = (item) => {
        return (
            <div className='thumbnailTemplate'>
                <img src={`${item.itemImageSrc}`} alt={item.alt} />
            </div>
        );
    };

    const { title, priceARS, priceUSD, description, images, category, mercadoLibreUrl } = producto;
    const imagesToShow = Array.isArray(images) ? images.slice(0, 10) : [];

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 3
        }
    ];

    return (
        <section className='section-list-detail'>
            <div className='filterContainerDetail'>
                <DetailBreadCrumb title={title} categoryName={category}/>
            </div>

            <div className="ItemDetail">
                <div className='sticky-gallery'>
                    <div className='galleria-container'>
                        <Galleria
                            ref={galleria}
                            value={imagesToShow.map(image => ({
                                itemImageSrc: `${image}`,
                                alt: title
                            }))}
                            responsiveOptions={responsiveOptions}
                            numVisible={numVisible}
                            circular
                            thumbnailsPosition={thumbnailsPosition}
                            style={{ width: '100%' }}
                            item={itemTemplate}
                            thumbnail={thumbnailTemplate}
                        />
                    </div>

                    <div className='interaction-container'>
                        {loading ? (
                                <div className='spinner-container'>
                                    <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                </div>
                            ) : (
                                <>
                                    <PaymentInfoLinks /> 
                                    <div className='btn-container'>
                                        <button onClick={handleAddToCart} disabled={loading || success} className="add-to-cart-button">Agregar al carrito</button>
                                    </div>
                                </>
                        )}
                    </div>

                </div>

                <div className='content-detail-item'>
                <div className='container-detalles'>
                        <h2 className="detail-title">{title}</h2>
                        <p className="detail-category">{category}</p> 
                        
                        <div className="price-and-link">
                           
                            <div className='content-prices'>
                                <h3 className="detail-price">
                                    {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' })
                                        .format(priceARS)} ARS
                                </h3>
                                <h3 className="detail-price">
                                    {priceUSD && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                        .format(priceUSD)} USD
                                </h3>
                            </div>

                            {mercadoLibreUrl && (
                                <a href={mercadoLibreUrl} target="_blank" rel="noopener noreferrer" className="mercadolibre-link">
                                    Ver en Mercado Libre
                                </a>
                            )}
                        </div>

                        <h4 className="detail-description">{description}</h4>
                    </div>


                    {error && <p>Error: {error}</p>}
                    {success && <p>¡Producto agregado al carrito!</p>}
                </div>
            </div>
            <ToastContainer />
        </section>
    );
}

export default ItemDetail;
