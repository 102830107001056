import React, { useState } from 'react';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';
import '../../styles/admin/selectedModifyProducts.css';
import useProductsAdmin from '../../hooks/useProductsAdmin.js';

const SelectedModifyPrices = () => {
  const { products, loading } = useProductsAdmin();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [amount, setAmount] = useState('');
  const [isPercentage, setIsPercentage] = useState(false);
  const [priceType, setPriceType] = useState('priceARS');
  const [actionType, setActionType] = useState('increase'); // NUEVO
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleCheckboxChange = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const resetForm = () => {
    setSelectedProducts([]);
    setAmount('');
    setIsPercentage(false);
    setPriceType('priceARS');
    setActionType('increase');
  };

  const handleSubmit = async () => {
    if (!amount || isNaN(parseFloat(amount))) {
      setResponseMessage('Por favor, ingresa un monto válido.');
      return;
    }

    const finalAmount = actionType === 'decrease' ? -Math.abs(parseFloat(amount)) : Math.abs(parseFloat(amount)); // NUEVO

    setIsLoading(true);
    try {
      const response = await fetch(`${REACT_APP_HOST_HOOKS_DEV}/api/products/modify-selected`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          productIds: selectedProducts,
          amount: finalAmount,
          isPercentage,
          priceType,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setResponseMessage(`Precio de ${data.length} productos modificados.`);
        resetForm();
      }
    } catch (error) {
      setResponseMessage('Error al actualizar precios.');
    } finally {
      setIsLoading(false);
    }
  };

  const filteredProducts = products.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section className="modify-prices-container">
      <div>
        <h1>Modificar precios de productos seleccionados</h1>
        <div className="modify-prices-form">
          <label>
            Acción:
            <select value={actionType} onChange={(e) => setActionType(e.target.value)}>
              <option value="increase">Aumentar</option>
              <option value="decrease">Disminuir</option>
            </select>
          </label>

          <label className="checkbox-group">
            <input
              type="checkbox"
              checked={isPercentage}
              onChange={() => setIsPercentage((prev) => !prev)}
            />
            ¿Modificar en porcentaje?
          </label>

          <label>
            Moneda a modificar:
            <select value={priceType} onChange={(e) => setPriceType(e.target.value)}>
              <option value="priceARS">ARS</option>
              <option value="priceUSD">USD</option>
              <option value="both">Ambos (ARS & USD)</option>
            </select>
          </label>
        </div>

        {loading ? (
          <div className="spinner"></div>
        ) : (
          <>
            <div className="modify-prices-form div-search-container">

            <div>
                <label>
                  Monto:
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="0"
                  />
                  <div className='div-span'>
                    <span className="note">* Para centenas y miles, NO usar puntos ni comas.</span>
                    <span className="note">* Para centavos, usar una COMA como separador.</span>
                  </div>
                </label>

            </div>

            <div>
                <label>
                  Buscar producto:
                  <input
                    type="text"
                    placeholder="Escribe el nombre del producto..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </label>
            </div>

            </div>

            <div className="product-selection-container">
              {filteredProducts.map((product) => (
                <div key={product._id} className="product-item">
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedProducts.includes(product._id)}
                      onChange={() => handleCheckboxChange(product._id)}
                    />
                    <span className="title-modify-selected">{product.title}</span> - ARS: {product.priceARS}, USD: {product.priceUSD}
                  </label>
                </div>
              ))}
              {filteredProducts.length === 0 && (
                <p className="no-results">No se encontraron productos con ese nombre.</p>
              )}
            </div>
          </>
        )}
      </div>

      <div>
        {selectedProducts.length > 0 && (
          <div className="selected-products-container">
            <h3>Productos seleccionados</h3>
            {selectedProducts.map((productId) => {
              const product = products.find((p) => p._id === productId);
              return (
                <div key={productId} className="selected-product">
                  <span>{product.title}</span>
                  <span>ARS: {product.priceARS}, USD: {product.priceUSD}</span>
                </div>
              );
            })}
          </div>
        )}

        <button onClick={handleSubmit}>{isLoading ? 'Cargando...' : 'Actualizar precios'}</button>
        {responseMessage && <p className="response-message">{responseMessage}</p>}
      </div>
    </section>
  );
};

export default SelectedModifyPrices;