import React, { useContext, useState } from 'react';
import '../../styles/admin/modifyGroup.css';
import { REACT_APP_HOST_HOOKS_DEV } from '../../config/config.js';
import { CategoriesContext } from '../../context/categoriesContext.js';

const ModifyGroup = () => {
    const [category, setCategory] = useState('');
    const [amount, setAmount] = useState('');
    const [isPercentage, setIsPercentage] = useState(false);
    const [priceType, setPriceType] = useState('both');
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [modifyAll, setModifyAll] = useState(false); 
    const [changeDirection, setChangeDirection] = useState('increase'); // Nuevo estado
    const { categories } = useContext(CategoriesContext);

    const resetForm = () => {
        setCategory('');
        setAmount('');
        setIsPercentage(false);
        setPriceType('both');
        setModifyAll(false);
        setChangeDirection('increase'); // Restablecer a "aumentar"
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        // Aplicar el signo según la dirección seleccionada
        const adjustedAmount = changeDirection === 'decrease' ? -Math.abs(parseFloat(amount)) : Math.abs(parseFloat(amount));

        try {
            const url = modifyAll 
                ? `${REACT_APP_HOST_HOOKS_DEV}/api/products/modify-all`
                : `${REACT_APP_HOST_HOOKS_DEV}/api/products/modify-by-category`;

            const body = modifyAll
                ? { amount: adjustedAmount, isPercentage, priceType }
                : { category, amount: adjustedAmount, isPercentage, priceType };

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            const data = await response.json();

            if (response.ok) {
                setResponseMessage(`Precio de ${data.length} productos modificados.`);
                resetForm(); 
            } else {
                setResponseMessage('Error al actualizar precios.');
            }
        } catch (error) {
            setResponseMessage('Error al actualizar precios.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="modify-prices-container">
            <h2>Modificar precios en cantidad</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Modificar todos los productos</label>
                    <div>
                        <input
                            type="checkbox"
                            checked={modifyAll}
                            onChange={() => setModifyAll(!modifyAll)}
                        />
                        <span className="note">Tildá esta opción si queres modificar todos los productos.</span>
                        <span className="note">Destildá si queres modificar solo una categoría.</span>
                    </div>
                </div>

                {!modifyAll && (
                    <div className="form-group">
                        <label>Categoría:</label>
                        <select
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                            required={!modifyAll}
                        >
                            <option value="">Seleccioná una categoría</option>
                            {categories.map((cat) => (
                                <option key={cat._id} value={cat.name}>
                                    {cat.name}
                                </option>
                            ))}
                        </select>
                    </div>
                )}

                <div className="form-group">
                    <label>Monto:</label>
                    <span className="note"> * Para centenas y miles, NO usar puntos ni comas. (se ajusta solo)</span>
                    <span className="note"> * Para centavos, usar una COMA como separador.</span>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>¿Modificar en porcentaje?</label>
                    <div>                    
                        <input
                            type="checkbox"
                            checked={isPercentage}
                            onChange={() => setIsPercentage((prev) => !prev)}
                        />
                        <span className="note">Tildá esta opción si queres modificar en porcentaje.</span>
                        <span className="note">Destildá si queres modificar por monto.</span>
                    </div>
                </div>

                <div className="form-group">
                    <label>Cambiar precio:</label>
                    <div>
                        <input
                            type="radio"
                            name="changeDirection"
                            value="increase"
                            checked={changeDirection === 'increase'}
                            onChange={(e) => setChangeDirection(e.target.value)}
                        />
                        Aumentar
                        <input
                            type="radio"
                            name="changeDirection"
                            value="decrease"
                            checked={changeDirection === 'decrease'}
                            onChange={(e) => setChangeDirection(e.target.value)}
                        />
                        Disminuir
                    </div>
                </div>

                <div className="form-group">
                    <label>Moneda a modificar:</label>
                    <select
                        value={priceType}
                        onChange={(e) => setPriceType(e.target.value)}
                    >
                        <option value="both">Ambos (ARS & USD)</option>
                        <option value="priceARS">Precio en ARS</option>
                        <option value="priceUSD">Precio en USD</option>
                    </select>
                </div>

                <button type="submit" className="submit-btn" disabled={isLoading}>
                    {isLoading ? 'Cargando...' : 'Modificar precios'}
                </button>
            </form>

            {responseMessage && <p className="response-message">{responseMessage}</p>}
        </div>
    );
};

export default ModifyGroup;