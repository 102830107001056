import React, { useState } from 'react';
import '../../styles/admin/toggleComponent.css';
import SelectedModifyPrices from './SelectedModifyPrices.jsx';
import ModifyGroup from './ModifyGroup.jsx';

const ToggleComponent = () => {
  const [currentComponent, setCurrentComponent] = useState(0);

  const toggleComponent = () => {
    setCurrentComponent((prev) => (prev === 0 ? 1 : 0));
  };

  return (
    <div className="toggle-container">
      <div className="button-container">
        <button className={`toggle-button ${currentComponent === 0 ? 'active' : ''}`} onClick={toggleComponent}>
          Modificar todos o por categoría
        </button>
        <button className={`toggle-button ${currentComponent === 1 ? 'active' : ''}`} onClick={toggleComponent}>
          Modificar productos seleccionados
        </button>
      </div>

      <div className="component-display">
        {currentComponent === 0 ? (
          <div className="first-component">
            <ModifyGroup />
          </div>
        ) : (
          <div className="second-component">
            <SelectedModifyPrices />
          </div>
        )}
      </div>
    </div>
  );
};

export default ToggleComponent;
